<template>
    <div class="settle-choice-slider">
		<div class="container">
			<div class="section-title">
				<h2>Choose Seattle Choice</h2>				
			</div>
		</div>
		<div class="container">
		<VueSlickCarousel v-bind="settings" class="settle-slide">
			<div class="column-inner">
				<div class="image">
					<img src="../assets/images/personal-experience.svg">
				</div>
				<h2>Making our customers our best need</h2>
				<p>By making our customers our best need, we are strategically positioning ourselves to become one of the leading trucking logistics companies in the US.</p>
			</div>
			<div class="column-inner">
				<div class="image">
					<img src="../assets/images/security-and-compliance.svg">
				</div>
				<h2>Discover a better way of shipping</h2>
				<p>Trust us to always handle your cargo with care. We always get the job done.</p>
			</div>
			<div class="column-inner">
				<div class="image">
					<img src="../assets/images/personal-experience.svg">
				</div>
				<h2>Earn</h2>
				<p>We offer a competitive base salary of $49k yearly, driving three times a week. If your goal is to be financially free, you’ve got to start taking steps to achieve that – join us now. You can also earn up to $500 when you refer a driver.</p>
			</div>
			<div class="column-inner">
				<div class="image">
					<img src="../assets/images/security-and-compliance.svg">
				</div>
				<h2>Earn</h2>
				<p>We offer a competitive base salary of $49k yearly, driving three times a week. If your goal is to be financially free, you’ve got to start taking steps to achieve that – join us now. You can also earn up to $500 when you refer a driver.</p>
			</div>
			<div class="column-inner">
				<div class="image">
					<img src="../assets/images/personal-experience.svg">
				</div>
				<h2>Earn</h2>
				<p>We offer a competitive base salary of $49k yearly, driving three times a week. If your goal is to be financially free, you’ve got to start taking steps to achieve that – join us now. You can also earn up to $500 when you refer a driver.</p>
			</div>
			<div class="column-inner">
				<div class="image">
					<img src="../assets/images/security-and-compliance.svg">
				</div>
				<h2>Earn</h2>
				<p>We offer a competitive base salary of $49k yearly, driving three times a week. If your goal is to be financially free, you’ve got to start taking steps to achieve that – join us now. You can also earn up to $500 when you refer a driver.</p>
			</div>
		</VueSlickCarousel>
		</div>	
    </div>
</template>
<script>
  import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
 
  export default {
    name: 'SettleSlider',
    data() {
      return {
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 2000,
            dots: false,
        },
      }
    },
    components: { VueSlickCarousel },
  }
</script> 